import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import CaseStudySection from './components/CaseStudySection';
import WhyChooseSection from './components/WhyChooseSection';
import VideoSection from './components/VideoSection';
import PricingSection from './components/PricingSection';
import GetStarted from './components/GetStarted'; // Import Get Started page

const App = () => {
  return (
    <Router>
      <div style={{ backgroundColor: '#0b0b0b', color: '#fff', minHeight: '100vh' }}>
        <Routes>
          {/* Home Page */}
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <FeaturesSection />
                <VideoSection />
                <CaseStudySection />
                <PricingSection />
                <WhyChooseSection />
                <ContactSection />
                <Footer />
              </>
            }
          />
          {/* Get Started Page */}
          <Route path="/get-started" element={<GetStarted />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
