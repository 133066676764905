import React from 'react';
import { Container, Typography, Card, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import Slider from 'react-slick';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Legend } from 'recharts';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Styled Components
const CaseStudyContainer = styled('div')({
  backgroundColor: '#1e1e1e',
  padding: '80px 20px',
  color: '#fff',
  textAlign: 'center',
});

const ChartCard = styled(Card)({
  backgroundColor: '#292929',
  color: '#fff',
  borderRadius: '15px',
  padding: '30px',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.25)',
  transition: 'transform 0.4s, box-shadow 0.4s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 40px rgba(0, 255, 0, 0.4)',
  },
});

const SectionTitle = styled(Typography)({
  margin: '40px 0 20px',
  fontSize: '1.8rem',
  fontWeight: 'bold',
  color: '#00FFFF',
  textTransform: 'uppercase',
});

const COLORS = ['#0f0', '#ff7300', '#00C49F', '#FFBB28'];

// Custom Arrows
const ArrowButton = styled(IconButton)({
  color: '#00FFFF',
  fontSize: '1.8rem',
  '&:hover': {
    color: '#00e6e6',
  },
});

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick} style={{ position: 'absolute', left: '-50px', zIndex: 10 }}>
    <ArrowBackIosNewIcon />
  </ArrowButton>
);

const NextArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick} style={{ position: 'absolute', right: '-50px', zIndex: 10 }}>
    <ArrowForwardIosIcon />
  </ArrowButton>
);

const CaseStudySection = () => {
  // Data for Charts
  const caseStudyData = [
    { month: 'Jan', fraudAttempts: 200, prevented: 180 },
    { month: 'Feb', fraudAttempts: 250, prevented: 240 },
    { month: 'Mar', fraudAttempts: 220, prevented: 210 },
    { month: 'Apr', fraudAttempts: 300, prevented: 290 },
    { month: 'May', fraudAttempts: 320, prevented: 310 },
  ];

  const chargebackData = [
    { month: 'Jan', chargebacks: 50, prevented: 45 },
    { month: 'Feb', chargebacks: 60, prevented: 55 },
    { month: 'Mar', chargebacks: 45, prevented: 43 },
    { month: 'Apr', chargebacks: 70, prevented: 65 },
    { month: 'May', chargebacks: 75, prevented: 70 },
  ];

  const fraudTypeData = [
    { type: 'Credit Card Fraud', occurrences: 100 },
    { type: 'ACH Fraud', occurrences: 50 },
    { type: 'Friendly Fraud', occurrences: 30 },
    { type: 'Chargeback Fraud', occurrences: 40 },
  ];

  const chargebackRecoveryData = [
    { type: 'Recovered', value: 85 },
    { type: 'Not Recovered', value: 15 },
  ];

  // Slider Settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <CaseStudyContainer>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ color: '#00FFFF' }}>
          Proven Results with Fraud Buster API
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Fraud Buster API has empowered businesses to reduce fraud, minimize chargebacks, and secure revenue through AI-driven fraud detection and prevention strategies.
        </Typography>

        {/* Slider */}
        <Slider {...sliderSettings}>
          {/* Fraud Attempts vs Prevention */}
          <div>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Fraud Attempts vs Prevention
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={caseStudyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="fraudAttempts" stroke="#ff7300" />
                  <Line type="monotone" dataKey="prevented" stroke="#0f0" />
                </LineChart>
              </ResponsiveContainer>
            </ChartCard>
          </div>

          {/* Chargebacks vs Prevention */}
          <div>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Chargebacks vs Prevention
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chargebackData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="chargebacks" stroke="#ff7300" />
                  <Line type="monotone" dataKey="prevented" stroke="#0f0" />
                </LineChart>
              </ResponsiveContainer>
            </ChartCard>
          </div>

          {/* Fraud Types Breakdown */}
          <div>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Fraud Types Breakdown
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={fraudTypeData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="type" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="occurrences" fill="#00FFFF" />
                </BarChart>
              </ResponsiveContainer>
            </ChartCard>
          </div>
        </Slider>

        {/* Summary */}
        <SectionTitle>The Results</SectionTitle>
        <Typography variant="body1" paragraph>
          Fraud Buster API prevented over 90% of fraud attempts and reduced chargebacks by 85%, recovering more than $750,000 in revenue. Businesses now enjoy improved security, fewer losses, and greater peace of mind.
        </Typography>
      </Container>
    </CaseStudyContainer>
  );
};

export default CaseStudySection;
