import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const VideoSectionContainer = styled('div')({
  backgroundColor: '#000',
  color: '#fff',
  padding: '100px 20px',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 1,
  backgroundImage: 'url(/your-background-image.jpg)', // Add a cool background image
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const VideoOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.7)', // Dark overlay for contrast
  zIndex: 2,
});

const Title = styled(motion.div)({
  position: 'relative',
  zIndex: 3,
  fontSize: '3rem',
  fontWeight: 700,
  textShadow: '0 0 20px rgba(0, 255, 255, 0.8), 0 0 30px rgba(255, 0, 255, 0.6)', // Neon glow
});

const Subtitle = styled(motion.div)({
  position: 'relative',
  zIndex: 3,
  fontSize: '1.2rem',
  marginBottom: '20px',
  textShadow: '0 0 15px rgba(0, 255, 255, 0.8)', // Neon glow for subtitle
});

const CTAButton = styled(Button)({
  zIndex: 3,
  backgroundColor: '#00FFFF',
  color: '#000',
  fontWeight: 700,
  fontSize: '1.1rem',
  padding: '10px 30px',
  boxShadow: '0 0 15px rgba(0, 255, 255, 0.8)',
  '&:hover': {
    backgroundColor: '#00e6e6',
  },
});

const VideoWrapper = styled(motion.div)({
  position: 'relative',
  paddingBottom: '56.25%', // 16:9 aspect ratio
  height: 0,
  overflow: 'hidden',
  marginTop: '50px',
  zIndex: 3,
  borderRadius: '15px',
  boxShadow: '0 0 20px rgba(0, 255, 255, 0.8), 0 0 30px rgba(255, 0, 255, 0.6)', // Neon glow around the video
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
    borderRadius: '15px',
  },
});

const VideoSection = () => {
  return (
    <VideoSectionContainer>
      <VideoOverlay />
      <Container maxWidth="md">
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Discover the Power of Fraud Buster API
        </Title>
        <Subtitle
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          Protect your transactions like never before with real-time fraud detection.
        </Subtitle>
        <CTAButton
          variant="contained"
          component="a"
          href="/get-started" // Add a CTA link to redirect users
        >
          Get Started
        </CTAButton>
        <VideoWrapper
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: 'easeInOut', delay: 0.5 }}
        >
          <iframe
            src="https://www.youtube.com/embed/TGZj84auB54"
            title="Fraud Buster API"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoWrapper>
      </Container>
    </VideoSectionContainer>
  );
};

export default VideoSection;

