import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  Container,
  Typography,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';

const PricingSectionContainer = styled('div')({
  backgroundColor: '#1e1e1e',
  color: '#fff',
  padding: '80px 20px',
  textAlign: 'center',
});

const PlanCard = styled(Card)({
  backgroundColor: '#292929',
  color: '#fff',
  borderRadius: '15px',
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.15)',
  transition: 'transform 0.4s, box-shadow 0.4s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 40px rgba(0, 255, 0, 0.3)',
  },
});

const PricingTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '2rem',
  marginBottom: '20px',
});

const FeatureList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  textAlign: 'left',
  marginTop: '20px',
});

const FeatureItem = styled('li')({
  marginBottom: '10px',
  fontSize: '1rem',
});

const CTAButton = styled(Button)({
  backgroundColor: '#00FFFF',
  color: '#000',
  fontWeight: 700,
  fontSize: '1.1rem',
  padding: '10px 30px',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#00e6e6',
  },
});

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: '#292929',
    color: '#fff',
    padding: '20px',
    borderRadius: '15px',
    boxShadow: '0 8px 30px rgba(0, 255, 0, 0.3)',
  },
});

const DialogTitleStyled = styled(DialogTitle)({
  fontWeight: 700,
  fontSize: '1.8rem',
  color: '#00FFFF',
});

const DialogContentStyled = styled(DialogContent)({
  fontSize: '1rem',
  lineHeight: '1.6',
});

const ArrowButton = styled('div')(({ direction }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: '#00FFFF',
  color: '#000',
  fontSize: '24px',
  cursor: 'pointer',
  boxShadow: '0 4px 15px rgba(0, 255, 255, 0.4)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  position: 'absolute',
  top: '50%',
  zIndex: 10,
  transform: 'translateY(-50%)',

  // Default positions for larger screens
  ...(direction === 'left' ? { left: '-60px' } : { right: '-60px' }),

  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 6px 20px rgba(0, 255, 255, 0.6)',
  },

  // Responsive styles for smaller screens
  '@media (max-width: 1200px)': {
    width: '45px',
    height: '45px',
    fontSize: '20px',
    ...(direction === 'left' ? { left: '-40px' } : { right: '-40px' }),
  },
  '@media (max-width: 800px)': {
    width: '40px',
    height: '40px',
    fontSize: '18px',
    ...(direction === 'left' ? { left: '-20px' } : { right: '-20px' }),
  },
  '@media (max-width: 500px)': {
    width: '35px',
    height: '35px',
    fontSize: '16px',
    ...(direction === 'left' ? { left: '5px' } : { right: '5px' }),
  },
}));


const CustomArrow = ({ direction, onClick }) => (
  <ArrowButton direction={direction} onClick={onClick}>
    {direction === 'left' ? '←' : '→'}
  </ArrowButton>
);


const PricingSection = () => {
  const [open, setOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handleOpen = (pkg) => {
    setSelectedPackage(pkg);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pricingPackages = [
    {
      name: 'Shared Plan',
      description: 'Affordable AI fraud detection for small teams.',
      price: '$99/month',
      features: [
        '16GB VRAM (RTX A4000 or similar)',
        'Limited usage (200 calls/month)',
        'Basic fraud detection',
        'Shared server environment',
      ],
      details:
        'An economical choice for small teams or individual users. Offers essential fraud detection features in a shared server environment with limited monthly usage. Perfect for starting out with AI-powered analytics.',
    },
    {
      name: 'Starter Plan',
      description: 'Ideal for small businesses and startups.',
      price: '$500/month',
      features: [
        '16GB VRAM (Tesla V100 or RTX A4000)',
        'Unlimited usage',
        'Advanced fraud detection',
        'AI-powered analytics',
      ],
      details:
        'Perfect for startups looking to secure transactions without breaking the bank. Includes AI analytics and advanced fraud detection. All servers are fully managed, and we provide secure API endpoints.',
    },
    {
      name: 'Business Plan',
      description: 'Designed for medium-sized businesses.',
      price: '$1,000/month',
      features: [
        '24GB VRAM (RTX 3090, L4, or RTX 4090)',
        'Unlimited usage',
        'Priority support',
        'Scalable performance',
        'Enhanced reporting tools',
      ],
      details:
        'Mid-sized businesses benefit from priority support and enhanced reporting, perfect for scaling operations securely. Fully managed servers with easy-to-use API endpoints.',
    },
    {
      name: 'Enterprise Plan',
      description: 'Built for large-scale operations and enterprises.',
      price: '$2,500/month',
      features: [
        '48GB VRAM (A40, L40, RTX A6000)',
        'Unlimited usage',
        'Dedicated support team',
        'AI-powered fraud mitigation',
        'Custom integrations and analytics',
      ],
      details:
        'Enterprise-grade solutions with AI-powered fraud mitigation and dedicated support for mission-critical applications. Managed servers and scalable API endpoints included.',
    },
    {
      name: 'Premium Enterprise Plan',
      description:
        'High-performance AI fraud prevention for enterprise-level needs.',
      price: '$5,000/month',
      features: [
        '80GB VRAM (A100 PCIe or A100 SXM)',
        'Unlimited usage',
        'AI-driven real-time monitoring',
        'Custom API integrations',
        'Dedicated account manager',
        'Fully managed servers with 24/7 monitoring',
        'Enterprise-grade security and compliance',
      ],
      details:
        'Custom integrations and real-time AI monitoring designed for enterprises requiring high performance. Servers are fully maintained, monitored, and optimized by our team. Secure API endpoints are provided for seamless integration.',
    },
    {
      name: 'Ultra Enterprise Plan',
      description: 'Maximum performance for mission-critical workloads.',
      price: '$7,500/month',
      features: [
        '94GB VRAM (H100 NVL or H100 PCIe)',
        'Unlimited usage',
        'Advanced AI analytics and fraud prevention',
        '24/7 priority support with guaranteed response times',
        'Secure cloud deployment options (hybrid/on-premise)',
        'Scalable infrastructure with high availability',
        'Fully managed servers with proactive monitoring',
      ],
      details:
        'Advanced AI analytics with 24/7 priority support and secure deployment for mission-critical systems. Servers are maintained, monitored, and optimized by our engineering team. Flexible deployment models support hybrid and on-premise setups.',
    },
    {
      name: 'Ultimate AI Plan',
      description:
        'The most powerful AI-driven fraud detection platform available.',
      price: '$10,000/month',
      features: [
        '192GB VRAM (MI300X or H200 SXM)',
        'Unlimited usage',
        'AI model customization and training',
        'Enterprise-grade security with encryption and compliance',
        'Dedicated engineering support for optimization',
        'Custom fraud models fine-tuned to your business',
        'Fully managed servers with high availability and redundancy',
      ],
      details:
        'Custom AI training tailored for your business, including fine-tuning models and optimization support. Servers are fully managed and monitored with redundancy and high availability. Secure API endpoints and dedicated engineering support are included to meet enterprise demands.',
    },
  ];

  // Slider settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomArrow direction="left" />,
    nextArrow: <CustomArrow direction="right" />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  return (
    <PricingSectionContainer>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Choose Your Plan
        </Typography>
        <Typography variant="subtitle1" paragraph>
          All plans come with unlimited usage, advanced fraud detection, and
          scalable performance. No hourly charges—flat monthly rates designed to
          match your business needs.
        </Typography>

        {/* Carousel Slider */}
        <Slider {...sliderSettings}>
          {pricingPackages.map((pkg) => (
            <div key={pkg.name}>
              <PlanCard>
                <PricingTitle>{pkg.name}</PricingTitle>
                <InfoIcon
                  onClick={() => handleOpen(pkg)}
                  style={{
                    cursor: 'pointer',
                    marginBottom: '10px',
                    color: '#00FFFF',
                  }}
                />
                <Typography variant="subtitle1" gutterBottom>
                  {pkg.description}
                </Typography>
                <Typography variant="h5" color="primary">
                  {pkg.price}
                </Typography>
                <FeatureList>
                  {pkg.features.map((feature, index) => (
                    <FeatureItem key={index}>- {feature}</FeatureItem>
                  ))}
                </FeatureList>
                <CTAButton variant="contained" href="/get-started">
                  Get Started
                </CTAButton>
              </PlanCard>
            </div>
          ))}
        </Slider>
        {/* End Carousel Slider */}

        {/* Modal Dialog for details */}
        <StyledDialog open={open} onClose={handleClose}>
          <DialogTitleStyled>{selectedPackage?.name}</DialogTitleStyled>
          <DialogContentStyled>
            {selectedPackage?.details}
          </DialogContentStyled>
        </StyledDialog>
      </Container>
    </PricingSectionContainer>
  );
};

export default PricingSection;




// import React, { useState } from 'react';
// import { Container, Grid, Typography, Card, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
// import { styled } from '@mui/system';
// import InfoIcon from '@mui/icons-material/Info';

// const PricingSectionContainer = styled('div')({
//   backgroundColor: '#1e1e1e',
//   color: '#fff',
//   padding: '80px 20px',
//   textAlign: 'center',
// });

// const PlanCard = styled(Card)({
//   backgroundColor: '#292929',
//   color: '#fff',
//   borderRadius: '15px',
//   padding: '20px',
//   textAlign: 'center',
//   boxShadow: '0 8px 30px rgba(0, 255, 0, 0.15)',
//   transition: 'transform 0.4s, box-shadow 0.4s',
//   '&:hover': {
//     transform: 'scale(1.05)',
//     boxShadow: '0 12px 40px rgba(0, 255, 0, 0.3)',
//   },
// });

// const PricingTitle = styled(Typography)({
//   fontWeight: 700,
//   fontSize: '2rem',
//   marginBottom: '20px',
// });

// const FeatureList = styled('ul')({
//   listStyle: 'none',
//   padding: 0,
//   textAlign: 'left',
//   marginTop: '20px',
// });

// const FeatureItem = styled('li')({
//   marginBottom: '10px',
//   fontSize: '1rem',
// });

// const CTAButton = styled(Button)({
//   backgroundColor: '#00FFFF',
//   color: '#000',
//   fontWeight: 700,
//   fontSize: '1.1rem',
//   padding: '10px 30px',
//   marginTop: '20px',
//   '&:hover': {
//     backgroundColor: '#00e6e6',
//   },
// });

// const StyledDialog = styled(Dialog)({
//   '& .MuiPaper-root': {
//     backgroundColor: '#292929',
//     color: '#fff',
//     padding: '20px',
//     borderRadius: '15px',
//     boxShadow: '0 8px 30px rgba(0, 255, 0, 0.3)',
//   },
// });

// const DialogTitleStyled = styled(DialogTitle)({
//   fontWeight: 700,
//   fontSize: '1.8rem',
//   color: '#00FFFF',
// });

// const DialogContentStyled = styled(DialogContent)({
//   fontSize: '1rem',
//   lineHeight: '1.6',
// });

// const PricingSection = () => {
//   const [open, setOpen] = useState(false);
//   const [selectedPackage, setSelectedPackage] = useState(null);
//   const [currentIndex, setCurrentIndex] = useState(0);


//   const handleOpen = (pkg) => {
//     setSelectedPackage(pkg);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % pricingPackages.length);
//   };
  
//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? pricingPackages.length - 1 : prevIndex - 1
//     );
//   };
  

//   const pricingPackages = [
//     {
//       name: 'Shared Plan',
//       description: 'Affordable AI fraud detection for small teams.',
//       price: '$99/month',
//       features: [
//         '16GB VRAM (RTX A4000 or similar)',
//         'Limited usage (200 calls/month)',
//         'Basic fraud detection',
//         'Shared server environment',
//       ],
//       details: 'An economical choice for small teams or individual users. Offers essential fraud detection features in a shared server environment with limited monthly usage. Perfect for starting out with AI-powered analytics.',
//     },
//     {
//       name: 'Starter Plan',
//       description: 'Ideal for small businesses and startups.',
//       price: '$500/month',
//       features: [
//         '16GB VRAM (Tesla V100 or RTX A4000)',
//         'Unlimited usage',
//         'Advanced fraud detection',
//         'AI-powered analytics',
//       ],
//       details: 'Perfect for startups looking to secure transactions without breaking the bank. Includes AI analytics and advanced fraud detection. All servers are fully managed, and we provide secure API endpoints.',
//     },
//     {
//       name: 'Business Plan',
//       description: 'Designed for medium-sized businesses.',
//       price: '$1,000/month',
//       features: [
//         '24GB VRAM (RTX 3090, L4, or RTX 4090)',
//         'Unlimited usage',
//         'Priority support',
//         'Scalable performance',
//         'Enhanced reporting tools',
//       ],
//       details: 'Mid-sized businesses benefit from priority support and enhanced reporting, perfect for scaling operations securely. Fully managed servers with easy-to-use API endpoints.',
//     },
//     {
//       name: 'Enterprise Plan',
//       description: 'Built for large-scale operations and enterprises.',
//       price: '$2,500/month',
//       features: [
//         '48GB VRAM (A40, L40, RTX A6000)',
//         'Unlimited usage',
//         'Dedicated support team',
//         'AI-powered fraud mitigation',
//         'Custom integrations and analytics',
//       ],
//       details: 'Enterprise-grade solutions with AI-powered fraud mitigation and dedicated support for mission-critical applications. Managed servers and scalable API endpoints included.',
//     },
//     {
//         name: 'Premium Enterprise Plan',
//         description: 'High-performance AI fraud prevention for enterprise-level needs.',
//         price: '$5,000/month',
//         features: [
//           '80GB VRAM (A100 PCIe or A100 SXM)',
//           'Unlimited usage',
//           'AI-driven real-time monitoring',
//           'Custom API integrations',
//           'Dedicated account manager',
//           'Fully managed servers with 24/7 monitoring',
//           'Enterprise-grade security and compliance',
//         ],
//         details: 'Custom integrations and real-time AI monitoring designed for enterprises requiring high performance. Servers are fully maintained, monitored, and optimized by our team. Secure API endpoints are provided for seamless integration.',
//       },
//       {
//         name: 'Ultra Enterprise Plan',
//         description: 'Maximum performance for mission-critical workloads.',
//         price: '$7,500/month',
//         features: [
//           '94GB VRAM (H100 NVL or H100 PCIe)',
//           'Unlimited usage',
//           'Advanced AI analytics and fraud prevention',
//           '24/7 priority support with guaranteed response times',
//           'Secure cloud deployment options (hybrid/on-premise)',
//           'Scalable infrastructure with high availability',
//           'Fully managed servers with proactive monitoring',
//         ],
//         details: 'Advanced AI analytics with 24/7 priority support and secure deployment for mission-critical systems. Servers are maintained, monitored, and optimized by our engineering team. Flexible deployment models support hybrid and on-premise setups.',
//       },
//       {
//         name: 'Ultimate AI Plan',
//         description: 'The most powerful AI-driven fraud detection platform available.',
//         price: '$10,000/month',
//         features: [
//           '192GB VRAM (MI300X or H200 SXM)',
//           'Unlimited usage',
//           'AI model customization and training',
//           'Enterprise-grade security with encryption and compliance',
//           'Dedicated engineering support for optimization',
//           'Custom fraud models fine-tuned to your business',
//           'Fully managed servers with high availability and redundancy',
//         ],
//         details: 'Custom AI training tailored for your business, including fine-tuning models and optimization support. Servers are fully managed and monitored with redundancy and high availability. Secure API endpoints and dedicated engineering support are included to meet enterprise demands.',
//       },
      
//   ];

//   return (
//     <PricingSectionContainer>
//       <Container maxWidth="lg">
//         <Typography variant="h4" gutterBottom>
//           Choose Your Plan
//         </Typography>
//         <Typography variant="subtitle1" paragraph>
//           All plans come with unlimited usage, advanced fraud detection, and scalable performance. No hourly charges—flat monthly rates designed to match your business needs.
//         </Typography>
//         <Grid container spacing={4}>
//           {pricingPackages.map((pkg) => (
//             <Grid item xs={12} md={4} key={pkg.name}>
//               <PlanCard>
//                 <PricingTitle>{pkg.name}</PricingTitle>
//                 <InfoIcon onClick={() => handleOpen(pkg)} style={{ cursor: 'pointer', marginBottom: '10px', color: '#00FFFF' }} />
//                 <Typography variant="subtitle1" gutterBottom>
//                   {pkg.description}
//                 </Typography>
//                 <Typography variant="h5" color="primary">
//                   {pkg.price}
//                 </Typography>
//                 <FeatureList>
//                   {pkg.features.map((feature, index) => (
//                     <FeatureItem key={index}>- {feature}</FeatureItem>
//                   ))}
//                 </FeatureList>
//                 <CTAButton variant="contained" href="/get-started">Get Started</CTAButton>
//               </PlanCard>
//             </Grid>
//           ))}
//         </Grid>
//         <StyledDialog open={open} onClose={handleClose}>
//           <DialogTitleStyled>{selectedPackage?.name}</DialogTitleStyled>
//           <DialogContentStyled>{selectedPackage?.details}</DialogContentStyled>
//         </StyledDialog>
//       </Container>
//     </PricingSectionContainer>
//   );
// };

// export default PricingSection;
