import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ShieldIcon from '@mui/icons-material/Shield';

// Styled Components
const GetStartedContainer = styled('div')({
  backgroundColor: '#141414',
  padding: '80px 20px',
  textAlign: 'center',
  color: '#fff',
  position: 'relative',
  overflow: 'hidden',
});

const AnimatedBackground = styled('div')({
  position: 'absolute',
  top: '-50%',
  left: '-50%',
  width: '200%',
  height: '200%',
  background: 'radial-gradient(circle at center, rgba(0, 255, 0, 0.2), transparent)',
  animation: 'rotation 20s infinite linear',
  zIndex: 0,
  '@keyframes rotation': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
});

const FormCard = styled(Card)({
  backgroundColor: '#1e1e1e',
  borderRadius: '15px',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.25)',
  color: '#fff',
  position: 'relative',
  zIndex: 1,
  padding: '30px',
});

const SectionTitle = styled(Typography)({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '40px',
  position: 'relative',
  zIndex: 1,
  color: '#00FFFF',
});

const Subtitle = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '40px',
  position: 'relative',
  zIndex: 1,
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const SubmitButton = styled(Button)({
  backgroundColor: '#0f0',
  color: '#000',
  padding: '12px 30px',
  borderRadius: '30px',
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#0fff0f',
  },
});

const StatsContainer = styled(Grid)({
  marginTop: '60px',
  position: 'relative',
  zIndex: 1,
});

const StatCard = styled(Card)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  textAlign: 'center',
  color: '#fff',
});

const StatIcon = styled('div')({
  fontSize: '3rem',
  color: '#0f0',
  marginBottom: '10px',
});

// Component
const GetStarted = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // Animated Counters
  const [businessesProtected, setBusinessesProtected] = useState(0);
  const [fraudTransactions, setFraudTransactions] = useState(0);
  const [modelsTrained, setModelsTrained] = useState(0);

  useEffect(() => {
    const animateValue = (setValue, target) => {
      let start = 0;
      const increment = target / 50;
      const interval = setInterval(() => {
        start += increment;
        if (start >= target) {
          setValue(Math.floor(target));
          clearInterval(interval);
        } else {
          setValue(Math.floor(start));
        }
      }, 50);
    };

    animateValue(setBusinessesProtected, 100);
    animateValue(setFraudTransactions, 150000);
    animateValue(setModelsTrained, 150); // Replacing "years of experience"
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xyzyygwl', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage(true);
        setFormData({ name: '', email: '', phone: '', message: '' });
      } else {
        setErrorMessage(true);
      }
    } catch (error) {
      setErrorMessage(true);
    }
  };

  const handleClose = () => {
    setSuccessMessage(false);
    setErrorMessage(false);
  };

  return (
    <GetStartedContainer>
      <AnimatedBackground />
      <Container maxWidth="md">
        <SectionTitle>Protect Your Business Today</SectionTitle>
        <Subtitle>
          Secure transactions, prevent fraud, and gain peace of mind. Enter your details and let us handle the rest!
        </Subtitle>

        {/* Form Section */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={8}>
            <FormCard>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Get Started Now
                </Typography>
                <Form onSubmit={handleSubmit}>
                  <TextField
                    name="name"
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.name}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                  <TextField
                    name="email"
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    required
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                  <TextField
                    name="phone"
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                  <TextField
                    name="message"
                    label="How can we help you?"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.message}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                  <SubmitButton type="submit">Submit</SubmitButton>
                </Form>
              </CardContent>
            </FormCard>
          </Grid>
        </Grid>

        {/* Stats Section */}
        <StatsContainer container spacing={4}>
          <Grid item xs={4}>
            <StatCard>
              <StatIcon><BusinessIcon /></StatIcon>
              <Typography variant="h4">{businessesProtected}+</Typography>
              <Typography>Businesses Secured</Typography>
            </StatCard>
          </Grid>
          <Grid item xs={4}>
            <StatCard>
              <StatIcon><ShieldIcon /></StatIcon>
              <Typography variant="h4">{fraudTransactions.toLocaleString()}</Typography>
              <Typography>Fraud Transactions Blocked</Typography>
            </StatCard>
          </Grid>
          <Grid item xs={4}>
            <StatCard>
              <StatIcon><VerifiedUserIcon /></StatIcon>
              <Typography variant="h4">{modelsTrained}+</Typography>
              <Typography>AI Models Deployed</Typography>
            </StatCard>
          </Grid>
        </StatsContainer>
      </Container>
    </GetStartedContainer>
  );
};

export default GetStarted;
